

















import { Vue, Component } from 'vue-property-decorator';
import { getStatic } from '@/utilities/static';

@Component
export default class ErrorMaintenance extends Vue {
  private ErrorIcon = getStatic('icons/errors/error-icon.svg');
  private Illustration = getStatic('icons/errors/505.svg');
}
